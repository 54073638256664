<template>
  <!-- Settings tab at campaign level -->
  <div class="card min-height-card u-spacing-p-l">
    <loader
      v-show="campaignInfo.status === 'loading'"
      class="fill--parent"
      :loading="campaignInfo.status === 'loading'"
      :color="'#3684bb'"
    />
    <!-- campaign name  -->

    <div class="u-spacing-pb-l">
      <div
        data-cy="headerText"
        class="u-spacing-pb-s u-font-size-5 u-font-weight-600"
      >
        Ad Group Name:
      </div>
      <div class="u-position-relative">
        <rb-input
          v-model="name"
          class="u-display-flex u-width-480px u-flex-align-items-center"
        />
      </div>
    </div>
    <div class="u-display-flex">
      <!-- status -->
      <div class="u-spacing-pb-l">
        <div class="u-spacing-pb-s u-font-size-5 u-font-weight-600">
          Status:
        </div>
        <rb-select
          v-if="tabConfig.statusChange"
          class="filter--token u-max-width-160px"
          :width="'260px'"
          :send-details="true"
          :on-close="setSelectedStatus"
          :options="statusValues"
          :disabled="true"
        >
          <div
            slot="trigger"
            class="u-display-flex u-cursor-pointer u-flex-justify-content-space-between"
          >
            <span class="u-font-size-6">
              {{ (selectedStatus && selectedStatus.title) || 'NA' }}
            </span>
            <rb-icon
              class="rb-icon--medium u-spacing-ml-xs u-color-grey-lighter"
              :icon="'caret-down'"
            />
          </div>
        </rb-select>
        <rb-input
          v-else
          :value="(selectedStatus || {}).title"
          class="u-max-width-160px"
          :disabled="true"
        />
      </div>
      <!-- Review status -->
      <div
        v-if="!tabConfig.hideReviewStatus"
        class="u-spacing-pb-l u-spacing-ml-l"
      >
        <div class="u-spacing-pb-s u-font-size-5 u-font-weight-600">
          Review Status:
        </div>
        <rb-input
          :value="reviewStatusData || 'NA'"
          class="u-max-width-160px u-text-case-title"
          :disabled="true"
        />
      </div>
    </div>
    <!-- Advertiser -->
    <div
      v-if="advertiser"
      class="u-spacing-pb-l"
    >
      <div class="u-spacing-pb-s u-font-size-5 u-font-weight-600">
        Advertiser:
      </div>
      <div class="u-position-relative u-display-flex">
        <rb-input
          v-model="advertiserName"
          :disabled="true"
          class="u-display-flex u-width-600px u-flex-align-items-center"
        />
      </div>
    </div>

    <div
      v-if="campaignMetdataChanged.length"
      class="u-spacing-pt-m"
    >
      <footerButtons
        :button-min-width="'u-min-width-100px'"
        @onClickBtnRight="onCancel"
        @onClickBtnLeft="onSave"
      />
    </div>
  </div>
</template>
<script>
import footerButtons from '@/pages/entity_details/common_components/footerButtons.vue';
import loader from '@/components/basic/loader';
import { deepReplaceObjectValues } from '@/pages/entity_details/common_components/helper.js';
import { cloneDeep, capitalize } from 'lodash';

export default {
  components: {
    footerButtons,
    loader
  },
  props: {
    tabMetadata: {
      default: () => {},
      type: Object
    },
    entityId: {
      default: null,
      type: String
    },
    entityConfig: {
      default: () => {},
      type: Object
    },
    parentEntityId: {
      default: null,
      type: String
    }
  },
  data() {
    return {
      getState: null,
      tabConfig: null,
      updateMetadata: null,
      name: null,
      selectedStatus: null,
      requestPayload: null,
      advertiser: true,
      advertiserName: 'NA',
      settingsRequestMap: {
        state: {
          state: ':state',
          view_old_state: ':oldState',
          view_adgroup_name: ':name'
        },
        name: {
          name: ':name',
          view_old_adgroup_name: ':oldName',
          view_adgroup_name: ':name'
        }
      },
      statusValues: [
        {
          title: 'Enabled',
          value: 'enabled'
        },
        {
          title: 'Disabled',
          value: 'disabled'
        }
      ]
    };
  },
  computed: {
    campaignMetdataChanged() {
      const selectedStatus = this.statusValues.filter(
        (item) => item.value === this.campaignInfo?.data?.state
      );
      const itemsChanged = [];
      if (this.name !== this.campaignInfo?.data?.name) {
        itemsChanged.push({
          value: 'name',
          ':name': this.name?.trim(),
          ':oldName': this.campaignInfo?.data?.name
        });
      }
      if (selectedStatus.length && this.selectedStatus !== selectedStatus[0]) {
        itemsChanged.push({
          value: 'status',
          ':state': this.selectedStatus?.value,
          ':oldState': this.campaignInfo?.data?.state,
          ':name': this.name
        });
      }
      return itemsChanged;
    },
    campaignInfo() {
      return this.$store.getters[this.getState];
    },
    advertiserInfo() {
      return this.$store.getters[this.tabConfig?.apiConfigs?.getAdvertiser];
    },
    reviewStatusData() {
      return capitalize(
        this.$store.getters[this.tabConfig?.apiConfigs?.getReviewStatusData]
          ?.data?.reviewStatus
      );
    }
  },
  watch: {
    campaignInfo: {
      handler(newValue, oldValue) {
        if (newValue?.status === 'loaded') {
          this.setCampaignDetails();
        }
      },
      immediate: true
    },
    advertiserInfo: {
      handler(newValue, oldValue) {
        if (newValue?.status === 'loaded') {
          this.setAdvertiserDetails();
        }
      },
      immediate: true
    }
  },
  created() {
    const tabConfig = this.entityConfig.tabs[this?.tabMetadata?.component];
    this.tabConfig = tabConfig;
    this.getState = tabConfig?.apiConfigs?.getter;
    this.updateMetadata = tabConfig?.apiConfigs?.updateAction;
    this.requestPayload = tabConfig?.requestPayload;
    this.getAdvertiser();
    this.getReviewStatusData();
  },
  methods: {
    onCancel() {
      this.setCampaignDetails();
    },
    onSave() {
      this.dispatchAction(this.campaignMetdataChanged);
    },

    setSelectedStatus(context, selection) {
      if (!selection.length) return;
      this.selectedStatus = selection[0];
    },

    setCampaignDetails() {
      const selectedStatus = this.statusValues.filter(
        (item) => item.value === this.campaignInfo?.data?.state
      );
      this.selectedStatus = selectedStatus.length && selectedStatus[0];
      this.name = this.campaignInfo?.data?.name || null;
    },

    dispatchAction(campaignMetdataChanged) {
      const payload = cloneDeep(this.requestPayload);
      const reqValues = {
        ':entityId': this.entityId,
        ':pageUrl': window.location.href,
        ':campaignId': this.parentEntityId,
        ':name': this.name,
        ':advertiserId': this.$route?.query?.advertiser_id,
        ...(this.tabConfig?.statusChange
          ? {
              ':state': this.selectedStatus?.value,
              ':oldState': this.campaignInfo?.data?.state
            }
          : {})
      };
      for (const item in reqValues) {
        deepReplaceObjectValues(payload, item, reqValues[item]);
      }
      const viewPayload = {};
      const actionPayload = {};
      for (const campaignMetdata of campaignMetdataChanged) {
        const itemReq = this.settingsRequestMap[campaignMetdata.value];
        for (const key in itemReq) {
          viewPayload[key] = campaignMetdata[itemReq[key]];
          if (!key.includes('view_')) {
            actionPayload[key] = campaignMetdata[itemReq[key]];
          }
        }
      }

      payload.actionPayload = { ...payload.actionPayload, ...actionPayload };
      payload.viewPayload = { ...payload.viewPayload, ...viewPayload };
      this.$store.dispatch(this.updateMetadata, {
        updateRequest: [payload],
        entityId: this.entityId,
        entityType: this.entityConfig.entityType,
        snackbar: this.$snackbar,
        callAdgroupFetch: true,
        entityName: {
          singular: 'Ad Group Setting',
          plural: 'Ad Group Settings',
          noCount: true
        },
        routeData: this.$route
      });
    },
    getAdvertiser() {
      if (this.tabConfig?.apiConfigs?.fetchAdvertiser) {
        this.$store.dispatch(this.tabConfig.apiConfigs.fetchAdvertiser, {
          requestParams: this.tabConfig.getAdvertiserRequestPayload(
            this.$route?.query?.advertiser_id
          )
        });
      }
    },
    getReviewStatusData() {
      if (this.tabConfig?.apiConfigs?.fetchReviewStatusData) {
        this.$store.dispatch(this.tabConfig.apiConfigs.fetchReviewStatusData, {
          type: 'REVIEW_STATUS',
          routeData: this.$route
        });
      }
    },
    setAdvertiserDetails() {
      this.advertiserName = this.advertiserInfo?.data?.[0]?.advertiser_name;
    }
  }
};
</script>
<style scoped lang="css">
.filter--token {
  border-radius: 2px;
}
.min-height-card {
  min-height: 630px;
}
</style>
